import React from 'react';
import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export function ShowCurrrentBalance() {

	const [currentBalance, setCurrentBalance] = useState(0);

	useEffect(() => {
		fetch('https://api.pseudonym.mx/api/user/info', {
     	method: "GET",
     	headers: {
       	"Content-Type": "application/json",
       	'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
     	}
   	}).then(response => {
			return response.json()
   	}).then(info => {
			setCurrentBalance( info.balance_cents )
		}).catch(err => console.log)

		return;
  }, [currentBalance]);

	return (
		<span>
			<b>Current Balance: </b> {currentBalance / 100}
		</span>
	);
}

export function AddFunds() {

	const [amountToAdd, setAmountToAdd] = useState(0);

	function submitAddFunds( e ) {

		let amount = document.getElementById( 'addFundsAmount' ).value;
		setAmountToAdd( amount )

		e.target.setAttribute("disabled", "1")

		console.log(e)

		fetch('https://api.pseudonym.mx/api/user/createinvoice', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
			body: JSON.stringify({ amount: amount })
    }).then(response => {
			console.log( response )
      return response.json()
    }).then(info => {
      //setCurrentBalawnce( info.balance_cents )
    })
	}

	return (
		<>
			<p>Add Funds</p>
			<Form>
				<Form.Control type="text" placeholder="$" id="addFundsAmount" />
				<Button variant="primary" onClick={submitAddFunds} >
        	Submit
      	</Button>
			</Form>
		</>
	);

}
