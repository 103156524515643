import React from 'react';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';

export function EmailList() {

  const tabulatorOptions = {
    ajaxURL: "https://api.pseudonym.mx/api/mailbox/list",
    ajaxConfig:{
        method:"POST", //set request type to Position
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"), //set specific content type
        },
    },
    ajaxError: function(xhr, textStatus, errorThrown){
      console.log( 'ajax error' )
    }
  }

  const columns = [
    { title: "User", field: "username" },
    { title: "Domain", field: "domain"},
  ];

  return (
    <>
      <p>Emails</p>
      <ReactTabulator
        columns={columns}
        options={tabulatorOptions}
        layout={"fitData"}
      />
    </>
  );
}
