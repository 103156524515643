import React from 'react';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';

export function DomainList() {

  const tabulatorOptions = {
    ajaxURL: "https://api.pseudonym.mx/api/domain/list",
    ajaxConfig:{
        method:"POST", //set request type to Position
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"), //set specific content type
        },
    },
    ajaxError: function(xhr, textStatus, errorThrown){
      console.log( 'ajax error' )
    }
  }

  const columns = [
    { title: "Domain", field: "domain" },
    { title: "Status", field: "status"},
    { title: "Expiration Date", field: "regExpireDate" }
  ];

  return (
    <>
      <p>Domains</p>
      <ReactTabulator
        columns={columns}
        options={tabulatorOptions}
        layout={"fitData"}
      />
    </>
  );
}
