import { useId, useState } from 'react';
import { useNavigate } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { DomainList } from './DomainList';
import { EmailList } from './EmailList';
import { ShowCurrrentBalance, AddFunds } from './Balance';


function Register() {

	const [domainPrice, setDomainPrice] = useState( 0 )
	const [domainSearchStatus, setDomainSearchStatus] = useState( false )
	const [domainSearch, setDomainSearch] = useState( false )
	const [domainSearchError, setDomainSearchError] = useState( false )

	let currentRegDomain = false;

	const domainPriceGet = () => {
    //event.preventDefault();

    fetch('https://api.pseudonym.mx/api/registry/price', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify({ domain: document.getElementById("domainSearchQuery").value })
    }).then(response => {
      return response.json()
    }).then(response => {
			console.log( response )
			setDomainPrice( response.response )
      return response
    })
  }

	const domainSearchSubmit = (event) => {
		event.preventDefault()
		let domain = document.getElementById("domainSearchQuery").value
		console.log('domainSearchSubmit ' + domain)
		currentRegDomain = domain;
		setDomainSearch( domain => domain = domain )
		domainSearchGet();


	}

	const domainSearchGet = () => {
		console.log("domainSearchGet: " + currentRegDomain)

		let data = {
      domain: currentRegDomain
    }

    fetch('https://api.pseudonym.mx/api/registry/lookup', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
				'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify(data)
    }).then(response => {

			//if( response.status == 401 ) navigate("/login");

			if( response.status != 200 ) {
				throw new Error('Domain lookup failure.');
			}

      return response.json()
    }).then(response => {
      console.log( response )

			if( response.response == "taken" ) {
				document.getElementById("domainSearch").style.visibility = 'visible';
    		document.getElementById("domainSearchResults").style.visibility = 'hidden';
				setDomainSearchStatus('taken')
				throw new Error('Domain is taken.')
			}

			setDomainSearchStatus('available')
			document.getElementById("domainSearch").style.visibility = 'hidden';
			document.getElementById("domainSearchResults").style.visibility = 'visible';

			domainPriceGet( data.domain )

    }).catch( e => {
			setDomainSearchError( String(e) )
			console.log( e )
			return
		})
	}

	const domainRegSubmit = (event) => {
    event.preventDefault();

    let data = {
      domain: document.getElementById("domainSearchQuery").value
    }

    fetch('https://api.pseudonym.mx/api/registry/register', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + localStorage.getItem("nymJwt"),
      },
      body: JSON.stringify(data)
    }).then(response => {
      console.log( response )
      return response.json()
    }).then(response => {
      console.log( response )

      if( response.response == "taken" ) {
        document.getElementById("domainSearch").style.visibility = 'visible';
        document.getElementById("domainSearchResults").style.visibility = 'hidden';
        console.log('Domain taken')
      }

			document.getElementById("domainRegistrationResults").style.visibility = 'visible';
      document.getElementById("domainSearchResults").style.visibility = 'hidden';

    })
  }

	return (
		<>
			{ domainSearchError &&
				<div>
					{domainSearchError}
				</div>
			}
			<div id="domainSearch">
				<Form>
					<Form.Group className="mb-3" controlId="domainSearchQuery">
        		<Form.Label>Domain</Form.Label>
        		<Form.Control type="" placeholder="example.com" />
      		</Form.Group>
					<Button variant="primary" type="submit" onClick={domainSearchSubmit} id="domainSerarchSubmit">
        		Search
      		</Button>
				</Form>
			</div>
			<div id="domainSearchResults" style={{visibility: "hidden"}}>
				<div>{currentRegDomain} @ {domainPrice}</div>
				<Button variant="primary" onClick={domainRegSubmit}>
					Buy domain
				</Button>
			</div>
			<div id="domainRegistrationResults" style={{visibility: "hidden"}}>
				<p>Registration Complete</p>
			</div>
		</>
	)

}

function Dash() {

	//const navigate = useNavigate();
	const [dashTab, setDashTab] = useState( 'Domains' )

	const dashNavClick = (event) => {
    event.preventDefault();
		setDashTab( event.target.dataset.dashpanel )
  };

  return (
		<>
			<Row>
				<Col>
					<p>Dash</p>
				</Col>
				<Col>
					username -  balance
				</Col>
			</Row>
			<Row>
				<Col xs={3}>
					<Row>
						<a className="navButton" onClick={dashNavClick} data-dashpanel="Domains">Domains</a>
					</Row>
					<Row>
						<a className="naveButton" onClick={dashNavClick} data-dashpanel="Register">Register</a>
					</Row>
					<Row>
            <a className="naveButton" onClick={dashNavClick} data-dashpanel="Balance">Balance</a>
          </Row>
				</Col>
				<Col>
					<Row>
						{ dashTab == 'Domains' &&
							<div id="dashContentDomains" className="dashContent">
								<div>
									<EmailList />
									</div>
								<div>
									<DomainList />
								</div>
							</div>
						}
						{ dashTab == 'Register' &&
							<div id="dashContentRegister" className="dashContent">
								<Register />
							</div>
						}
						{ dashTab == 'Balance' &&
							<div id="dashContentBalance" className="dashContent">
								<ShowCurrrentBalance />
								<AddFunds />
							</div>
						}
					</Row>
				</Col>
			</Row>
		</>
  );
}

export default Dash;
